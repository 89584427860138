p {
  color: #e9e9e9;
  width: 30%;
  padding: 0 10%;
}

.head2 {
  color: #df8282;
  font-size: 4em;
  padding: 0 5%;
}

.head2::first-letter {
  color: #e9e9e9 !important;
}

h3 {
  color: #e9e9e9;
  padding: 0 10%;
  font-size: 2em;
}

.head3 {
  color: #92beff;
  font-size: 4em;
  padding: 0 5%;
}

.abutton {
  display: block;
  width: fit-content;
}

.imgsingel {
  display: inline-block;
  width: 29%;
  margin: 3% 10%;
  border: 1px white solid;
  border-radius: 10px;
}

.grid4by4 {
  display: inline-block;
  width: 29%;
  margin: 3% 10%;
  border: 1px white solid;
  border-radius: 10px;
}
img {
  width: 50%;
  height: auto;
}

.imgsingel > img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.head3::first-letter {
  color: #e9e9e9 !important;
}

#abuttoncode {
  background-color: #92beff;
  margin-left: 165px;
  margin-top: 50px;
  margin-bottom: 50px;
}

#abuttoncode2 {
  background-color: #92beff;
  margin-left: 120px;
}

.desktopShowCode {
  width: auto;
  padding-right: 42.1px;
  border: 1px #92beff solid;
  border-radius: 20px;
  height: auto;
  margin: 0 110px;
  margin-bottom: 160px;
}

.desktopShowCode > img {
  height: 449px;
  width: auto;
  margin: 42.1px 0 42.1px 42.1px;
}

#img2{
  height: 380px;
}
