button {
  background-color: #92beff; 
  border: none;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 16px;
  font-size: 16px;
  margin: 50px 30px;
  transition-duration: 0.4s;
  cursor: pointer;
  color: #1d1d1d;
  border: 1px solid #1d1d1d;
  font-weight: bold;
}

.button:hover {
  background-color: #1d1d1d;
  color: #dadada;
  border: 1px solid #dadada;
}

button {
  margin: 0 20px;
  background-color: #fff4bf;
  color: #1d1d1d;
  border: 1px solid #1d1d1d;
}

button.code {
  background-color: #92beff;
  color: #1d1d1d;
  border: 1px solid #1d1d1d;
}

button.other {
  background-color: #df8282;
  color: #1d1d1d;
  border: 1px solid #1d1d1d;
}

button.abuteMe {
  background-color: white;
  color: #1d1d1d;
  border: 1px solid #1d1d1d;
}

.abutton {
  background-color: #92beff; 
  border: none;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 16px;
  font-size: 14px;
  margin: 80px 20px;
  margin-top: 0;
  margin-left: 7.5%;
  transition-duration: 0.4s;
  cursor: pointer;
  color: #1d1d1d;
  border: 1px solid #1d1d1d;
  font-weight: bold;
}

.abutton:hover {
  background-color: #1d1d1d;
  color: #dadada;
  border: 1px solid #dadada;
}