footer {
  border-top: 1px solid #dadada;
  background-color: #252525;
  padding: 0px;
  width: 100%;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  height: 190px;
  position: relative;
}

footer img {
  width: 30px;
  height: auto;
  margin: 10px;
}

#contact {
  text-align: right;
  padding: 0 60px 20px 20px;
}
.contact h3 {
  padding-left: 5px;
  font-size: 1.5em;
}
.contact {
  display: inline-block;
  margin-left: 50px;
}
.abuteMeFooter {
  display: inline-block;
  margin-left: 4%;
}

#abuteMe {
  font-size: 1em;
  width: 140px;
  background-color: white;
  margin-left:69%;
  
}

.madeBy {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 10px;
  width: fit-content;
}
