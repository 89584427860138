.abuteMeB {
  background-image: url("/public/img/abuteMe/backround.png");
  background-size: cover;
}
section {
  margin: 0 100px;
}
.titel {
  padding-left: 50px;
  font-size: 4em;
  font-weight: 10;
  padding-bottom: 0;
}

article > div {
  display: inline-block;
  width: 50%;
}

.left {
  width: 400px;
}

.left > img {
  width: 400px;
  height: 400px;
  border-radius: 25px;
}

.left > p {
  width: 280px;
  padding: 0;
  margin: 0;
  display: inline-block;
}

.right > p {
  margin: 0;
  width: 60%;
  padding-top: 20px;
}

.right {
  width: 600px;
  margin-right: 500px;
  margin-bottom: 200px;
}

.mySkills {
  margin-bottom: 10%;
  border-radius: 22px;
  background-color: gray;
  height: 500px;
  display: block;
  position: relative;
  padding-left: 50px;
}

.mySkills > h2 {
  padding-top: 50px;
  padding-left: 20px;
}
.info {
  color: white;
  width: 270px;
  padding-left: 0;
  line-height: 1.7em;
  margin-left: 0;
}
.iconBox {
  padding-top: 30px;
}

.iconBox > img {
  padding: 20px;
  width: 100px;
  height: 100px;
}

.form {
  margin: 200px 0;
  width: 80%;
  height: 480px;
  border-radius: 25px;
  border: 1px solid white;
  padding: 0 40px;
  position: relative;
}

.form > h2 {
  font-size: 2em;
  font-weight: 10;
}
#email {
  width: 50%;
  height: 2em;
  margin-bottom: 20px;
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
}

#message {
  width: 80%;
  height: 233px;
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
}

#submit {
  position: absolute;
  bottom: 30px;
  right: 50px;
  background-color: #7e8596;
  border: 1px solid black;
  border-radius: 25px;
  height: 40px;
  width: 100px;
  color: white;
}
