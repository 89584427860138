html {
  background: #1d1d1d;
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
}

header {
  margin: 50px 100px;
}
p {
  margin-block-start: 1px;
  margin-block-end: 1px;
}

h1 {
  font-size: 3em;
}

h1,
h2 {
  color: white;
  display: inline-block;
  padding-left: 10px;
}

a {
  color: #dadada;
}

a:hover,
a:active {
  color: #acacac;
}

.buttondiv {
  display: inline-block;
  float: right;
  padding-right: 20px;
  padding-top: 1.67em;
}

.buttondivbottom {
  margin-left: 28%;
  position: relative;
}

.buttondivbottom > button {
  margin: 10%;
}

.vl {
  border-left: 1px solid white;
  height: 400px;
  position: absolute;
  left: 28%;
  top: 0;
}

#name {
  display: inline-block;
}

.code h1::first-letter {
  color: #92beff;
}
.code h2::first-letter {
  color: #92beff;
}

.other h1::first-letter {
  color: #df8282;
}
.other h2::first-letter {
  color: #df8282;
}

.design h1::first-letter {
  color: #fff4bf;
}
.design h2::first-letter {
  color: #fff4bf;
}

.abuteMe h1::first-letter {
  color: white;
}
.abuteMe h2::first-letter {
  color: white;
}
